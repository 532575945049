import { KEYCLOAK_CONFIG, ROUTING } from '@/constants';
import store from '@/store';

const navigationGuard = {
  async setupAuthorization() {
    if (!store.getters['isAuthenticated']) {
      await store.dispatch('initApp');
    }
    return store.getters['isAuthenticated']
      ? ROUTING.USER_ROLE.AUTHORIZED
      : ROUTING.USER_ROLE.NOT_AUTHORIZED;
  },
  getUserAttribute() {
    return store.getters[`userAttribute`];
  },
  setupDefaultRoute(userAttribute) {
    switch (userAttribute) {
      case KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE:
        return ROUTING.PATH.WALLETS;
      default:
        return ROUTING.PATH.NOT_FOUND;
    }
  },
};

export default navigationGuard;
