import { orderBy } from 'lodash';

import { SETTINGS, WALLETS } from '@/constants';

const walletsManager = {
  embedWallets(wallets) {
    const orderedWallets = orderWallets(wallets);
    let rank = 1;
    return orderedWallets.map((wallet) => {
      const labelAndOwner = findLabelAndOwner(wallet, WALLETS.ACCOUNTS);
      return {
        rank: rank++,
        label: labelAndOwner.label,
        owner: labelAndOwner.owner,
        address: wallet.address,
        com: wallet[SETTINGS.TOKENS.DENOM.UCOMMERCIO] / Math.pow(10, 6),
        ccc: wallet[SETTINGS.TOKENS.DENOM.UCCC] / Math.pow(10, 6),
        share:
          wallet[SETTINGS.TOKENS.DENOM.UCOMMERCIO] /
          Math.pow(10, 6) /
          SETTINGS.TOKENS.AMOUNT,
      };
    });
  },
  mergeWallets(wallets) {
    const result = wallets.reduce((acc, { address, value }) => {
      acc[address] ??= { address: address, value: [] };
      if (Array.isArray(value)) {
        acc[address].value = acc[address].value.concat(value);
      } else {
        acc[address].value.push(value);
      }
      return acc;
    }, {});
    return result;
  },
  sumWalletValues(wallets) {
    const items = [];
    for (const k in wallets) {
      const wallet = wallets[k];
      const item = {
        address: wallet.address,
        [SETTINGS.TOKENS.DENOM.UCOMMERCIO]: 0,
        [SETTINGS.TOKENS.DENOM.UCCC]: 0,
      };
      wallet.value.forEach((val) => {
        const amount = parseInt(val.amount);
        if (val.denom === SETTINGS.TOKENS.DENOM.UCOMMERCIO) {
          item[SETTINGS.TOKENS.DENOM.UCOMMERCIO] += amount;
        }
        if (val.denom === SETTINGS.TOKENS.DENOM.UCCC) {
          item[SETTINGS.TOKENS.DENOM.UCCC] += amount;
        }
      });
      items.push(item);
    }
    return items;
  },
};

export default walletsManager;

const findLabelAndOwner = (wallet, accounts) => {
  const index = accounts.findIndex(
    (account) => account.address === wallet.address,
  );
  if (index < 0) {
    return { label: '', owner: '' };
  }
  const account = accounts[index];
  return { label: account.label, owner: account.owner };
};

const orderWallets = (wallets) =>
  orderBy(wallets, [[SETTINGS.TOKENS.DENOM.UCOMMERCIO]], ['desc']);
