const KEYCLOAK_CONFIG = Object.freeze({
  CLIENT_ID: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
  REALM: process.env.VUE_APP_KEYCLOAK_REALM,
  URL: process.env.VUE_APP_KEYCLOAK_URL,
  ON_LOAD: 'check-sso',
  REFRESH_TOKEN_TIME: 60,
  USER_ATTRIBUTE: {
    NAME: process.env.VUE_APP_KEYCLOAK_USER_ATTRIBUTE_NAME,
    VALUE: process.env.VUE_APP_KEYCLOAK_USER_ATTRIBUTE_VALUE,
  },
});

export default KEYCLOAK_CONFIG;
