import Vue from 'vue';
import VueRouter from 'vue-router';

import { ROUTING } from '@/constants';
import navigationGuard from './navigation-guard';
import loginRoute from './routes/login-route';
import notFoundRoute from './routes/not-found-route';
import walletsRoute from './routes/wallets-route';

Vue.use(VueRouter);

const routes = [
  {
    path: ROUTING.PATH.ROOT,
    redirect: () => ROUTING.PATH.WALLETS,
    meta: {
      auth: ROUTING.USER_ROLE.AUTHORIZED,
    },
  },
];

routes.push(loginRoute, walletsRoute, notFoundRoute);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, _from, next) => {
  if (to.meta.auth === ROUTING.USER_ROLE.ANY) {
    next();
    return;
  }

  const userRole = await navigationGuard.setupAuthorization();

  if (userRole === ROUTING.USER_ROLE.NOT_AUTHORIZED) {
    next(ROUTING.PATH.LOGIN);
    return;
  } else {
    const userAttribute = navigationGuard.getUserAttribute();
    if (to.meta.tags && to.meta.tags.indexOf(userAttribute) > -1) {
      next();
      return;
    } else {
      const route = navigationGuard.setupDefaultRoute(userAttribute);
      next(route);
      return;
    }
  }
});

export default router;

export function loadPage(page) {
  return () =>
    import(/* webpackChunkName: "page-[request]" */ `@/pages/${page}.vue`);
}
