<template>
  <v-list dense nav>
    <v-list-item-group>
      <BaseDrawerMenuItem
        :icon="mdiWallet"
        :title="$t('titles.wallets')"
        :to="{ name: ROUTING.NAME.WALLETS }"
      />
    </v-list-item-group>
  </v-list>
</template>

<script>
import BaseDrawerMenuItem from './BaseDrawerMenuItem.vue';

import { mdiWallet } from '@mdi/js';
import { ROUTING } from '@/constants';

export default {
  name: 'TheDrawerRoutes',
  components: { BaseDrawerMenuItem },
  data() {
    return {
      ROUTING,
      mdiWallet,
    };
  },
};
</script>
