const LCD = process.env.VUE_APP_LCD;
const API_VERSION = process.env.VUE_APP_API_VERSION;

const APIS = Object.freeze({
  AUTH: `${LCD}/cosmos/auth/${API_VERSION}`,
  BANK: `${LCD}/cosmos/bank/${API_VERSION}`,
  DISTRIBUTION: `${LCD}/cosmos/distribution/${API_VERSION}`,
  STAKING: `${LCD}/cosmos/staking/${API_VERSION}`,
});

export default APIS;
