import { APIS } from '@/constants';
import axios from 'axios';

const bankApi = {
  /**
   *
   * @param {String} address
   * @returns {AxiosPromise}
   */
  requestBalances(address) {
    return axios({
      url: `/balances/${address}`,
      method: 'get',
      baseURL: `${APIS.BANK}`,
    });
  },
};

export default bankApi;
