<template>
  <v-list dense nav>
    <v-list-item-group>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon color="primary" class="text-h3">
            {{ mdiAccountCircleOutline }}
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="title pb-1" v-text="fullName" />
          <v-list-item-subtitle v-text="email" />
          <v-list-item-subtitle v-text="phoneNumber" />
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mdiAccountCircleOutline } from '@mdi/js';
import { mapGetters } from 'vuex';

export default {
  name: 'TheDrawerAccount',
  data() {
    return { mdiAccountCircleOutline };
  },
  computed: {
    ...mapGetters(['profile']),
    email() {
      return this.profile && this.profile.email ? this.profile.email : '';
    },
    fullName() {
      return this.profile && this.profile.firstName && this.profile.lastName
        ? `${this.profile.firstName} ${this.profile.lastName}`
        : '';
    },
    phoneNumber() {
      return this.profile &&
        this.profile.attributes &&
        this.profile.attributes.phoneNumber &&
        this.profile.attributes.phoneNumber.length > 0
        ? this.profile.attributes.phoneNumber[0]
        : '';
    },
  },
};
</script>
