import { APIS } from '@/constants';
import axios from 'axios';

const stakingApi = {
  /**
   *
   * @param {String} address
   * @returns {AxiosPromise}
   */
  requestDelegations(address) {
    return axios({
      url: `/delegations/${address}`,
      method: 'get',
      baseURL: `${APIS.STAKING}`,
    });
  },
  /**
   *
   * @param {String} address
   * @returns {AxiosPromise}
   */
  requestUnbondings({ address, limit, nextKey }) {
    const params = { 'pagination.limit': limit };
    if (nextKey) {
      params['pagination.key'] = nextKey;
    }
    return axios({
      url: `/delegators/${address}/unbonding_delegations`,
      method: 'get',
      baseURL: `${APIS.STAKING}`,
      params,
    });
  },
};

export default stakingApi;
