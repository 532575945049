const labels = {
  address: 'Address',
  ccc: 'CCC',
  com: 'COM',
  lang: 'Language',
  name: 'Name',
  rank: 'Rank',
  refresh: 'Refresh',
  search: 'Search',
  share: 'Share',
  signOut: 'Sign out',
};

export default labels