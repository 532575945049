const SETTINGS = Object.freeze({
  COPYRIGHT: 'Commercio.network SpA',
  EXPLORER_URL: process.env.VUE_APP_EXPLORER,
  PREFIX: {
    ACCOUNT: {
      ADDRESS: 'did:com:',
      KEY: 'did:com:pub',
    },
    VALIDATOR: {
      CONSENSUS: {
        ADDRESS: 'did:com:valcons',
        KEY: 'did:com:valconspub',
      },
      OPERATOR: {
        ADDRESS: 'did:com:valoper',
        KEY: 'did:com:valoperpub',
      },
    },
  },
  TOKENS: {
    AMOUNT: 60 * 1000000,
    DENOM: {
      CCC: 'ccc',
      COM: 'com',
      UCCC: 'uccc',
      UCOMMERCIO: 'ucommercio',
    },
  },
});

export default SETTINGS;
