const ROUTING = Object.freeze({
  PATH: {
    LOGIN: '/login',
    NOT_FOUND: '/:notFound(.*)',
    ROOT: '/',
    WALLETS: '/wallets',
  },
  NAME: {
    NOT_FOUND: 'NotFound',
    WALLETS: 'Wallets',
  },
  TITLE: {
    NOT_FOUND: 'Not Found',
    WALLETS: 'Wallets',
  },
  USER_ROLE: {
    ANY: 'any',
    AUTHORIZED: 'authorized',
    NOT_AUTHORIZED: 'notAuthorized',
  },
});

export default ROUTING;
