import { Semaphore } from 'async-mutex';

import { authApi, bankApi, distributionApi, stakingApi } from '@/apis';
import { SETTINGS, WALLETS } from '@/constants';
import { bech32Manager } from '@/utils';

export default {
  async initWalletsList({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchWallets')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchWallets({ commit }) {
    const accounts = await fetchAccounts(WALLETS.LIMIT);
    const requests = buildRequests(accounts);
    const semaphore = new Semaphore(WALLETS.SEMAPHORE_ITEMS);
    for (const request of requests) {
      // eslint-disable-next-line no-unused-vars
      const [_, release] = await semaphore.acquire();
      try {
        const response = await request;
        if (response.value.length > 0) commit('addWallet', response);
      } catch (error) {
        commit('setError', error);
      } finally {
        release();
      }
    }
  },
};

const buildRequests = (accounts) => {
  const requests = [];
  accounts.forEach((account) => {
    const address = account.address;
    if (address)
      requests.push(
        fetchBalances(address),
        fetchCommission(address),
        fetchDelegations(address),
        fetchRewards(address),
        fetchUnbondings(address),
      );
  });
  return requests;
};

const fetchAccounts = async (limit) => {
  try {
    const response = await authApi.requestAccounts(limit);
    return response.data.accounts;
  } catch (error) {
    throw new Error(error);
  }
};

const fetchBalances = async (address) => {
  try {
    const response = await bankApi.requestBalances(address);
    return { address, value: response.data.balances };
  } catch (error) {
    throw new Error(error);
  }
};

const fetchCommission = async (address) => {
  try {
    const hex = bech32Manager.decode(address);
    const validator = bech32Manager.encode(
      hex,
      SETTINGS.PREFIX.VALIDATOR.OPERATOR.ADDRESS,
    );
    const response = await distributionApi.requestCommission(validator);
    return { address, value: response.data.commission.commission };
  } catch (error) {
    // throw new Error(error);
  }
};

const fetchDelegations = async (address) => {
  try {
    const response = await stakingApi.requestDelegations(address);
    const delegations = [];
    response.data.delegation_responses.forEach((res) =>
      delegations.push(res.balance),
    );
    return {
      address,
      value: delegations,
    };
  } catch (error) {
    throw new Error(error);
  }
};

const fetchRewards = async (address) => {
  try {
    const response = await distributionApi.requestRewards(address);
    return { address, value: response.data.total };
  } catch (error) {
    throw new Error(error);
  }
};

const fetchUnbondings = async (address) => {
  const unbondings = [];
  const limit = 25;
  let nextKey = null;
  do {
    try {
      const response = await stakingApi.requestUnbondings({
        address,
        limit,
        nextKey,
      });
      for (const r of response.data.unbonding_responses) {
        for (const entry of r.entries) {
          unbondings.push({
            amount: entry.balance,
            denom: SETTINGS.TOKENS.DENOM.UCOMMERCIO,
          });
        }
      }
      nextKey = response.data.pagination?.next_key || null;
    } catch (error) {
      throw new Error(error);
    }
  } while (nextKey);
  return { address, value: unbondings };
};
