const labels = {
  address: 'Indirizzo',
  ccc: 'CCC',
  com: 'COM',
  lang: 'Lingua',
  name: 'Nome',
  rank: 'Rank',
  refresh: 'Ricarica',
  search: 'Cerca',
  share: 'Share',
  signOut: 'Esci',
};

export default labels